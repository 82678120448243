import React, { useReducer, createContext, useEffect } from 'react';
import { useNavigate } from 'react-router';
import jwtDecode from 'jwt-decode';
import config from '../config.json';
import axios from 'axios';

const initialState = {
    user: null
}

var hasToken = false;

function getUserData(token) {
    return axios.post(config.NCAUTH_URL + "/api/token-to-user", {token: token});
}

function verifyToken(token) {
    return axios.post(config.NCAUTH_URL + "/api/verify-token", {token: token});
}

if (localStorage.getItem('jwtToken')) {
    var currentToken = localStorage.getItem('jwtToken');
    const isValidToken = /^([a-zA-Z0-9_=]+)\.([a-zA-Z0-9_=]+)\.([a-zA-Z0-9_\-\+\/=]*)/gi.test(currentToken);

    if (isValidToken) {
        const decodedToken = jwtDecode(localStorage.getItem('jwtToken'));
        if (decodedToken.exp * 1000 < Date.now()) {
            localStorage.removeItem('jwtToken');
        }
        else {
            hasToken = true;
        }
    }
    else {
        localStorage.removeItem('jwtToken');
    }
}

const AuthContext = createContext({
    user: null,
    login: (userData) => {},
    logout: () => {}
});

function authReducer(state, action) {
    switch(action.type) {
        case 'LOGIN':
            return {
                ...state,
                user: action.payload
            }
        case 'LOGOUT':
            return{
                ...state,
                user: null
            }
        default:
            return state;
    }
}

function AuthProvider(props) {
    const [state, dispatch] = useReducer(authReducer, initialState);
    const navigate = useNavigate();

    useEffect(() => {
        if (hasToken) {
            verifyToken(currentToken).then(function(response) {
                if (response['data']['status'] == "OK") {
                    getUserData(currentToken).then(function(response) {
                        dispatch({
                            type: 'LOGIN',
                            payload: response['data']['data']
                        })
                    });
                }
                else {
                    localStorage.removeItem('jwtToken');
                }
            });
        }
    }, []);

    function login(userData) {
        localStorage.setItem("jwtToken", userData.token);
        dispatch({
            type: 'LOGIN',
            payload: userData
        })
    }

    function reloadUser() {
        getUserData(localStorage.getItem("jwtToken")).then(function(response) {
            dispatch({
                type: 'LOGIN',
                payload: response['data']['data']
            })
        });
    }

    function logout() {
        localStorage.removeItem("jwtToken");
        dispatch({ type: 'LOGOUT'});
        navigate('/');
    }

    return (
        <AuthContext.Provider
            value={{ user: state.user, login, logout, reloadUser}}
            {...props}
        />
    )
}

export { AuthContext, AuthProvider }