import React, { useContext, useState } from 'react';
import { AuthContext } from '../context/auth';
import { Link, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { faCartShopping } from '@fortawesome/free-solid-svg-icons';
import '../App.css';
import './Navbar.css';

export default function Navbar() {
  const { user, logout } = useContext(AuthContext);
  return (
    <>
      <div className="container">
        <nav className="navbar navbar-expand-lg navbar-light pt-2">
          <div className="container-fluid">
            <Link to="/" className="navbar-brand"><h3>MSGBOX</h3></Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <NavLink to="/" className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}>Início</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/precos" className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}>Preços</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/cliente" className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}>Área de Cliente</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/registar-empresa" className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}>Registar Empresa</NavLink>
                </li>
              </ul>
              <div className="d-flex">

                {user && <button className="btn btn-primary btn-lg btn-blue me-2" type="button" onClick={logout}>Sair</button>}
                {!user && <NavLink to="/entrar" className="btn btn-primary btn-lg btn-blue me-2" type="button">Entrar</NavLink>}
                {!user && <NavLink to="/registar" className="btn btn-primary btn-lg btn-blue me-2" type="button">Registar</NavLink>}
                
                {/*<button className="btn btn-link text-blue" data-bs-toggle="modal" data-bs-target="#search-modal">
                  <FontAwesomeIcon icon={faMagnifyingGlass} />
                </button>*/}
                <Link to="/carrinho" className="btn btn-link text-blue">
                  <FontAwesomeIcon icon={faCartShopping} />
                </Link>
              </div>
            </div>
          </div>
        </nav>
      </div>

      <div className="modal" id="search-modal" tabindex="-1">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Pesquisar</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary btn-lg btn-grey" data-bs-dismiss="modal">Cancelar</button>
              <button type="button" className="btn btn-primary btn-lg btn-blue">Pesquisar</button>
            </div>
          </div>
        </div>
      </div>
    </>
    
  )
}
