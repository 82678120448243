import React, {useContext} from 'react';
import smsImage from '../images/sms.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faMinus } from '@fortawesome/free-solid-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { CarrinhoDeComprasContext } from '../context/CarrinhoDeComprasContext.js';

export default function ItemCarrinho(props) {
  const [carrinhoDeCompras, setCarrinhoDeCompras] = useContext(CarrinhoDeComprasContext);

  const increaseQtty = (e) => {
    let index = props.index;
    setCarrinhoDeCompras(prevCarrinhoDeCompras => (
      prevCarrinhoDeCompras.map(
        el => el.index == index ? {...el, qtty: el.qtty + 1}: el
      )
    ));
  };

  const decreaseQtty = (e) => {
    let toRemove = false;
    let index = props.index;

    for (let i in carrinhoDeCompras) {
      let item = carrinhoDeCompras[i];
      if (item.index == index && item.qtty == 1) {
        toRemove = true;
      }
    }

    if (!toRemove) {
      setCarrinhoDeCompras(prevCarrinhoDeCompras => (
        prevCarrinhoDeCompras.map(
          el => el.index == index ? {...el, qtty: el.qtty - 1}: el
        )
      ));
    }
    else {
      setCarrinhoDeCompras([
        ...carrinhoDeCompras.slice(0, index),
        ...carrinhoDeCompras.slice(index + 1)
      ]);
    }
  }

  const removeItem = (e) => {
    let index = props.index;
    setCarrinhoDeCompras([
      ...carrinhoDeCompras.slice(0, index),
      ...carrinhoDeCompras.slice(index + 1)
    ]);
  }
 
  return (
    <>
      <div class="row mb-4 d-flex justify-content-between align-items-center">
        <div class="col-md-2 col-lg-2 col-xl-2">
          <img
            src={smsImage}
            class="img-fluid rounded-3" alt="Cotton T-shirt" />
        </div>
        <div class="col-md-3 col-lg-3 col-xl-3">
          <h6 class="text-muted">SMS</h6>
          <h6 class="text-black mb-0">{props.title}</h6>
        </div>
        <div class="col-md-3 col-lg-3 col-xl-3 d-flex">
          <button class="btn btn-link px-2"
            onClick={decreaseQtty}>
            <FontAwesomeIcon icon={faMinus} />
          </button>

          <input id="form1" min="0" name="quantity" value={props.qtty} type="number"
            class="form-control form-control-sm" />

          <button class="btn btn-link px-2"
            onClick={increaseQtty}>
            <FontAwesomeIcon icon={faPlus} />
          </button>
        </div>
        <div class="col-md-2 col-lg-2 col-xl-2 offset-lg-1">
          <h6 class="mb-0">{props.price}</h6>
        </div>
        <div class="col-md-1 col-lg-1 col-xl-1 text-end">
          <button class="btn btn-link px-2" onClick={removeItem}><FontAwesomeIcon icon={faTimes} size="2x"/></button>
        </div>
      </div>

      <hr class="my-4" />
    </>
  )
}
