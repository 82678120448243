import React from 'react'
import Hero from './Hero.js';
import Precos from './Precos.js';
import Footer from './Footer.js';

export default function Inicio() {
  return (
    <>
      <Hero />
      <Precos />
      <Footer />
    </>
  )
}
