import React from 'react';
import { Link } from 'react-router-dom';
import '../App.css';

export default function Hero() {
  return (
    <>
      <div>
        <div class="bg-lightpink text-secondary px-4 py-5 text-center">
          <div class="py-5">
            <h1 class="display-5 fw-bold text-white">MSGBOX</h1>
            <div class="col-lg-6 mx-auto">
              <p class="fs-5 mb-4 text-white">SMS Self-Service. Comunicação através de SMS. Envie SMS em massa usando o nosso serviço.</p>
              <div class="d-grid gap-2 d-sm-flex justify-content-sm-center">
                <Link type="button" to="/registar" class="btn btn-outline-light btn-lg px-4">Inscreva-se</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
