import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';

import { AuthContext } from '../context/auth';

function AuthRoute({ children }) {
    const { user } = useContext(AuthContext);

    return user ? <Navigate to="/cliente" /> : children;
}

export default AuthRoute;