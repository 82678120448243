import React from 'react';
import RegisterForm from './RegisterForm.js';
import Footer from './Footer.js';

export default function Registar() {
  return (
    <>
      <RegisterForm />
      <Footer />
    </>
  )
}
