import React, { useContext, useState } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import logo from './logo.svg';
//import './App.css';
import $ from 'jquery';

import { AuthProvider }  from './context/auth';
import { AuthContext } from './context/auth';
import AuthRoute from './util/AuthRoute';

import { CarrinhoDeComprasProvider } from './context/CarrinhoDeComprasContext';

import Navbar from './components/Navbar.js';
import Inicio from './components/Inicio.js';
import PrecosPage from './components/PrecosPage.js';
import Cliente from './components/Cliente.js';
import Registar from './components/Registar.js';
import RegistarEmpresa from './components/RegistarEmpresa.js';
import Entrar from './components/Entrar.js';
import CarrinhoDeComprasPage from './components/CarrinhoDeComprasPage.js';


function App() {
  const context = useContext(AuthContext);

  return (
    <Router>
      <AuthProvider>
        <CarrinhoDeComprasProvider>
          <div className="App">
            <Navbar />
            <Routes>
              <Route path="/" element={<Inicio />} />
              <Route path="/precos" element={<PrecosPage />} />
              <Route path="/cliente" element={<Cliente />} />
              <Route path="/carrinho" element={<CarrinhoDeComprasPage />} />
              <Route path="/entrar" element={<AuthRoute><Entrar/></AuthRoute>}/>
              <Route path="/registar" element={<AuthRoute><Registar/></AuthRoute>}/>
              <Route path="/registar-empresa" element={<RegistarEmpresa/>}/>
            </Routes>
          </div>
        </CarrinhoDeComprasProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
