import React from 'react';
import CarrinhoDeCompras from './CarrinhoDeCompras.js';
import Footer from './Footer.js';

export default function CarrinhoDeComprasPage() {
  return (
    <>
        <CarrinhoDeCompras />
        <Footer />
    </>
  )
}
