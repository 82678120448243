import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import '../App.css';
import './CarrinhoDeCompras.css';
import { CarrinhoDeComprasContext } from '../context/CarrinhoDeComprasContext.js';
import ItemCompra from './ItemCompra.js';
import mbLogo from '../images/mb.png';
import mbwayLogo from '../images/mbway.png';
import $ from 'jquery';
import axios from 'axios';
import config from '../config.json';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
window.jQuery = $;
window.$ = $;
global.jQuery = $;
const bootstrap = require('bootstrap');

const MySwal = withReactContent(Swal);

export default function Compra(props) {
  const [carrinhoDeCompras, setCarrinhoDeCompras] = useContext(CarrinhoDeComprasContext);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [mbPayment, setMbPayment] = useState({
    "Amount": "", 
    "Entity": "", 
    "ExpiryDate": "", 
    "Message": "", 
    "OrderId": "", 
    "Reference": "", 
    "RequestId": "", 
    "Status": ""
  });

  const [mbwayPaymentForm, setMbwayPaymentForm] = useState({
    telemovel: "",
  })

  const items = carrinhoDeCompras.map( ({index, title, price, qtty}) =>
    <ItemCompra key={index} index={index} title={title} qtty={qtty.toString()} price={"€ " + (price * qtty).toFixed(2).toString()} />
  );

  function showPaymentMethod() {
    $(".payment-method-modal").modal("show");
  }

  function submitPaymentMethod(e) {
    e.preventDefault()
    $(".payment-method-modal").modal("hide");
    if (paymentMethod == "mb") {
      var data = {
        orderId: props.order_id,
        amount: props.totalPriceNumeric,
        description: "Pacote SMS"
      }
      
      axios.post(config.PAYMENTS_URL + "/mb-payment", data)
      .then(function(response) {
        setMbPayment(response['data']);
        $(".mb-modal").modal("show");
      })
      .catch(function(err) {
        MySwal.fire({
          text: "Ocorreu um erro a efetuar o pagamento por Multibanco."
        });
      });
      
    }
    else if (paymentMethod == "mbway") {
      $(".mbway-form-modal").modal("show");
    }
    
  }

  function submitMbwayForm(e) {
    e.preventDefault();
    $(".mbway-form-modal").modal("hide");
    var data = {
      referencia: props.order_id,
      valor: props.totalPriceNumeric,
      nrtlm: mbwayPaymentForm.telemovel,
      descricao: "Pacote SMS"
    };

    axios.post(config.PAYMENTS_URL + "/mbway-payment", data)
    .then(function(response) {
      MySwal.fire({
        text: "Pode prosseguir com o pagamento na app MB-WAY."
      });
    })
    .catch(function(err) {
      MySwal.fire({
        text: "Ocorreu um erro a efetuar o pagamento por Multibanco."
      });
    });
  }

  function closeMbPayment() {
    $(".mb-modal").modal("hide");
  }

  function changePaymentMethod(e) {
    setPaymentMethod(e.target.value);
  }

  function changeMbwayTelemovel(e) {
    setMbwayPaymentForm({
      telemovel: e.target.value
    })
  }

  return (
    <>
      <section class="bg-lightpink">
        <div class="container py-5">
          <div class="row d-flex justify-content-center align-items-center">
            <div class="col-12">
              <div class="card card-registration card-registration-2" style={{borderRadius: "15px"}}>
                <div class="card-body p-0">
                  <div class="row g-0">
                    <div class="col-lg-8">
                      <div class="p-5">

                        <div class="d-flex justify-content-between align-items-center mb-5">
                          <h1 class="fw-bold mb-0 text-black">Compra #{props.order_id}</h1>
                          <h6 class="mb-0 text-muted">{props.totalItems} items</h6>
                        </div>

                        <hr class="my-4" />

                        <table class="table">
                            {items}
                        </table>
                      </div>
                    </div>
                    <div class="col-lg-4 bg-grey">
                      <div class="p-5">
                        <h3 class="fw-bold mb-5 mt-2 pt-1">Sumário</h3>
                        <hr class="my-4" />

                        <div class="d-flex justify-content-between mb-4">
                          <h5 class="text-uppercase">Items: {props.totalItems}</h5>
                        </div>

                        <hr class="my-4" />

                        <div class="d-flex justify-content-between mb-5">
                          <h5 class="text-uppercase">Total</h5>
                          <h5>{props.totalPriceStr}</h5>
                        </div>

                        <div class="d-flex justify-content-between mb-5">
                          <h5 class="text-uppercase">Nr. SMS</h5>
                          <h5>{props.nr_sms}</h5>
                        </div>

                        <button type="button" class="btn btn-primary btn-blue btn-block btn-lg"
                          data-mdb-ripple-color="dark" onClick={showPaymentMethod}>Efetuar Pagamento</button>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="modal fade payment-method-modal">
        <div className="modal-dialog" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h1 className="modal-title">Método de pagamento</h1>
                </div>
                <div className="modal-body">
                    <form role="form" onSubmit={submitPaymentMethod}>
                        <div className="form-group py-2">
                            <div class="form-check my-3">
                              <input class="form-check-input" type="radio" id="payment-method-radio1" name="paymentMethod" value="mb" checked={paymentMethod == "mb"} onChange={changePaymentMethod} />
                              <label class="form-check-label" for="payment-method-radio1">
                                <img src={mbLogo} style={{width: "75px"}} />
                              </label>
                            </div>
                            <div class="form-check my-3">
                              <input class="form-check-input" type="radio" id="payment-method-radio2" name="paymentMethod" value="mbway" checked={paymentMethod == "mbway"} onChange={changePaymentMethod} />
                              <label class="form-check-label" for="payment-method-radio2">
                                <img src={mbwayLogo} style={{width: "100px"}} />
                              </label>
                            </div>
                        </div>
                        
                        <div className="form-group">
                            <div style={{textAlign: "right"}}>
                                <button type="submit" className="btn btn-primary">Submeter</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
      </div>
      <div className="modal fade mb-modal">
        <div className="modal-dialog" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h1 className="modal-title">Pagamento Multibanco</h1>
                </div>
                <div className="modal-body" style={{textAlign: "left"}}>
                    <p><b>Entidade: </b> {mbPayment.Entity}</p>
                    <p><b>Referência: </b> {mbPayment.Reference}</p>
                    <p><b>Valor: </b> {mbPayment.Amount}€</p>
                    <p><b>Data de Expiração: </b> {mbPayment.ExpiryDate}</p>
                    <div style={{textAlign: "right"}}>
                        <button className="btn btn-primary" onClick={closeMbPayment}>Fechar</button>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <div className="modal fade mbway-form-modal">
        <div className="modal-dialog" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h1 className="modal-title">Pagamento MB-WAY</h1>
                </div>
                <div className="modal-body">
                  <form role="form" onSubmit={submitMbwayForm}>
                      <div className="form-group py-2">
                        <label className="form-label text-darkblue">Nr. de Telemóvel</label>
                        <input className="form-control textbox-blue" type="text" name="telemovel" value={mbwayPaymentForm.telemovel} onChange={changeMbwayTelemovel} />
                      </div>
                      
                      <div className="form-group">
                          <div style={{textAlign: "right"}}>
                              <button type="submit" className="btn btn-primary">Submeter</button>
                          </div>
                      </div>
                  </form>
                </div>
            </div>
        </div>
      </div>
      
    </>
  )
}
