import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../App.css';
import './CarrinhoDeCompras.css';
import ItemCarrinho from './ItemCarrinho.js';
import { CarrinhoDeComprasContext } from '../context/CarrinhoDeComprasContext.js';
import Compra from './Compra.js';
import { AuthContext } from '../context/auth.js';
import config from '../config.json';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal);
const axios = require('axios');

export default function CarrinhoDeCompras() {
  const [carrinhoDeCompras, setCarrinhoDeCompras] = useContext(CarrinhoDeComprasContext);
  const [orderCreated, setOrderCreated] = useState(false);
  const [order, setOrder] = useState({});
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const totalItems = carrinhoDeCompras.length;
  const totalPriceNumeric = carrinhoDeCompras.map(a => a.price * a.qtty).reduce((partialSum, a) => partialSum + a, 0).toFixed(2);
  const totalPriceStr = "€ " + totalPriceNumeric;
  
  

  const items = carrinhoDeCompras.map( ({index, title, price, qtty}) =>
    <ItemCarrinho key={index} index={index} title={title} qtty={qtty.toString()} price={"€ " + (price * qtty).toFixed(2).toString()} />
  );

  function checkModuleActive(id_company) {
    var data = {
      id_company: id_company,
      module_name: "NCSMS"
    }

    return axios.post(config.NCAUTH_URL + "/api/verify-active", data);
  }

  function createOrder(e) {
    if (user) {
      checkModuleActive(user['empresa']).then(function(response) {
        console.log(response['data']);
        if (response['data']['status'] == "OK") {
          if (response['data']['data']['company_status'] == 1) {
            if (response['data']['data']['module_status'] == 1) {
              let nr_sms = carrinhoDeCompras.map(a => a.nr * a.qtty).reduce((partialSum, a) => partialSum + a, 0);
              axios.post(config.BASE_URL + '/orders/new', {user_id: user['id_user'], amount: totalPriceNumeric, nr_sms: nr_sms, description: "Pacote SMS"})
              .then(function (response) {
                if (response['data']['status'] == "OK") {
                  setOrder(<Compra order_id={response['data']['order_id']} totalItems={totalItems} totalPriceStr={totalPriceStr} nr_sms={nr_sms} totalPriceNumeric={totalPriceNumeric} />);
                  setOrderCreated(true);
                }
                else {
                  MySwal.fire({
                    text: "Ocorreu um erro a efetuar a compra."
                  });
                }
              })
              .catch(function (error) {
                console.log(error);
              });
            }
            else {
              MySwal.fire({
                text: "O módulo está desativado."
              });
            }
          }
          else {
            MySwal.fire({
              text: "A empresa está desativada."
            });
          }
        }
        else {
          MySwal.fire({
            text: "Ocorreu um erro a efetuar a compra."
          });
        }
      });
      
    }
    else {
      navigate("/entrar");
    }
  }

  return (
    <>
    {orderCreated && order}
    {!orderCreated && 
      <section class="bg-lightpink">
        <div class="container py-5">
          <div class="row d-flex justify-content-center align-items-center">
            <div class="col-12">
              <div class="card card-registration card-registration-2" style={{borderRadius: "15px"}}>
                <div class="card-body p-0">
                  <div class="row g-0">
                    <div class="col-lg-8">
                      <div class="p-5">

                        <div class="d-flex justify-content-between align-items-center mb-5">
                          <h1 class="fw-bold mb-0 text-black">Carrinho de Compras</h1>
                          <h6 class="mb-0 text-muted">{totalItems} items</h6>
                        </div>

                        <hr class="my-4" />

                        {items}

                        <div class="pt-5">
                          <h6 class="mb-0"><Link to="/" class="text-body"><i
                                class="fas fa-long-arrow-alt-left me-2"></i>Voltar</Link></h6>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 bg-grey">
                      <div class="p-5">
                        <h3 class="fw-bold mb-5 mt-2 pt-1">Sumário</h3>
                        <hr class="my-4" />

                        <div class="d-flex justify-content-between mb-4">
                          <h5 class="text-uppercase">Items: {totalItems}</h5>
                        </div>

                        <hr class="my-4" />

                        <div class="d-flex justify-content-between mb-5">
                          <h5 class="text-uppercase">Total</h5>
                          <h5>{totalPriceStr}</h5>
                        </div>

                        <button type="button" class="btn btn-primary btn-blue btn-block btn-lg"
                          data-mdb-ripple-color="dark" onClick={createOrder}>Comprar</button>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    }
  </>
  );
}
