import React from 'react';
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <>
      <footer class="container-fluid bg-dark py-5 my-0">
        <div class="row">
          <div class="col-md-4">
            <small class="d-block mb-3 text-white">NovosCanais &copy; {new Date().getFullYear()}</small>
          </div>
          <div class="col-md-4 text-white">
            <h5>Produtos</h5>
            <ul class="list-unstyled text-small">
              <li><Link to="/" class="text-muted">MSGBOX</Link></li>
              <li><Link to="/precos"class="text-muted">Preços</Link></li>
            </ul>
          </div>
          <div class="col-md-4 text-white">
            <h5>Clientes</h5>
            <ul class="list-unstyled text-small">
              <li><Link to="/entrar" class="text-muted">Entrar</Link></li>
              <li><Link to="/registar" class="text-muted">Registar</Link></li>
              <li><Link to="/registar-empresa" class="text-muted">Registar Empresa</Link></li>
              <li><Link to="/cliente" class="text-muted">Área de Cliente</Link></li>
              <li><Link to="/carrinho" class="text-muted">Carrinho de Compras</Link></li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  )
}
