import React from 'react';
import RegisterCompanyForm from './RegisterCompanyForm.js';
import Footer from './Footer.js';

export default function RegistarEmpresa() {
  return (
    <>
      <RegisterCompanyForm />
      <Footer />
    </>
  )
}
