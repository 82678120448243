import React, {useState, createContext} from 'react';

export const CarrinhoDeComprasContext = createContext();

export const CarrinhoDeComprasProvider = (props) => {
    const [carrinhoDeCompras, setCarrinhoDeCompras] = useState([]);

    return (<CarrinhoDeComprasContext.Provider value={[carrinhoDeCompras, setCarrinhoDeCompras]}>
            {props.children}
          </CarrinhoDeComprasContext.Provider>);
}