import React, { useContext, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useForm } from '../util/hooks';
import { AuthContext } from '../context/auth';
import config from '../config.json';
import './RegisterForm.css';
import '../App.css';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal);
const axios = require('axios');

export default function RegisterForm() {
  const context = useContext(AuthContext);
  const navigate = useNavigate();

  const { onChange, onSubmit, values } = useForm(registerUser, {
      fname: "",
      lname: "",
      username: "",
      email: "",
      password: "",
      confirmPassword: ""
  });

  function createUser(id_company) {
    var data = {
      usercode: values.username,
      email: values.email,
      firstName: values.fname,
      lastName: values.lname,
      id_company: id_company,
      password: values.password,
      passwordConfirm: values.confirmPassword
    }

    axios.post(config.BASE_URL + "/api/create-user", data)
    .then(function(response) {
      console.log(response['data']);
      if (response['data']['status'] == "OK") {
        MySwal.fire({
          text: "Utilizador registado com sucesso. Por favor inicie sessão."
        });
        navigate("/entrar");
      }
      else {
        MySwal.fire({
          text: "Ocorreu um erro a registar o utilizador."
        });
      }
    })
    .catch(function(err) {
      console.log("Error: " + err.message);
      MySwal.fire({
        text: "Ocorreu um erro a registar o utilizador."
      });
    });
  }

  function requestRegister() {
    axios.post(config.NCAUTH_URL + "/api/company-exists", {nif: values.company_nif})
    .then(function (response) {
      if (response['data']['status'] == "OK") {
        createUser(response['data']['data']['id_company']);
      }
      else {
        MySwal.fire({
          text: "A empresa não existe. Por favor registe nova empresa."
        });
        navigate("/registar-empresa");
      }
    })
    .catch(function (error) {
      console.log(error);
      MySwal.fire({
        text: "Ocorreu um erro a registar o utilizador."
      });
    });
  }

  function registerUser() {
    requestRegister();
  }
  
  return (
    <>
      <div className="row justify-content-center py-5">
        <div className="col-md-4 form-wrapper p-5">
          <form onSubmit={onSubmit} noValidate>
              <h3 className="text-darkblue form-title">Registar</h3>
              <div className="form-group my-4" style={{textAlign: "left"}}>
                  <label className="form-label text-darkblue">Primeiro Nome</label>
                  <input className="form-control textbox-blue" type="text" name="fname" value={values.fname} onChange={onChange} />
              </div>
              <div className="form-group my-4" style={{textAlign: "left"}}>
                  <label className="form-label text-darkblue">Último Nome</label>
                  <input className="form-control textbox-blue" type="text" name="lname" value={values.lname} onChange={onChange} />
              </div>
              <div className="form-group my-4" style={{textAlign: "left"}}>
                  <label className="form-label text-darkblue">Nome de Utilizador</label>
                  <input className="form-control textbox-blue" type="text" name="username" value={values.username} onChange={onChange} />
              </div>
              <div className="form-group my-4" style={{textAlign: "left"}}>
                  <label className="form-label text-darkblue">Email</label>
                  <input className="form-control textbox-blue" type="text" name="email" value={values.email} onChange={onChange} />
              </div>
              <div className="form-group my-4" style={{textAlign: "left"}}>
                  <label className="form-label text-darkblue">NIF Empresa</label>
                  <input className="form-control textbox-blue" type="text" name="company_nif" value={values.company_nif} onChange={onChange} />
              </div>
              <div className="form-group my-4" style={{textAlign: "left"}}>
                  <label className="form-label text-darkblue">Password</label>
                  <input className="form-control textbox-blue" type="password" name="password" value={values.password} onChange={onChange} />
              </div>
              <div className="form-group my-4" style={{textAlign: "left"}}>
                  <label className="form-label text-darkblue">Confirmar Password</label>
                  <input className="form-control textbox-blue" type="password" name="confirmPassword" value={values.confirmPassword} onChange={onChange} />
              </div>
              <div className="my-5" style={{textAlign: "right"}}>
                <button type="submit" className="btn btn-primary btn-lg btn-blue">Submeter</button>
              </div>
          </form>
        </div>
      </div>
    </>
  )
}
