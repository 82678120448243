import React, { useContext, useState } from 'react';
import { useNavigate, Link } from "react-router-dom";
import { useForm } from '../util/hooks';
import { AuthContext } from '../context/auth';
import config from '../config.json';
import '../App.css';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal);
const axios = require('axios');

export default function LoginForm() {
  const context = useContext(AuthContext);
  const navigate = useNavigate();

  const { onChange, onSubmit, values } = useForm(loginUserCallback, {
      username: '',
      password: '',
      nif_empresa: ''
  });

  function requestLogin() {
    var data = {
      username: values.username,
      password: values.password,
      module_name: "SMS Self-Service",
      nif_empresa: values.nif_empresa
    }
    axios.post(config.NCAUTH_URL + "/api/login-user-email", data)
    .then(function (response) {
      if (response['data']['status'] == "OK") {
        var token = response['data']['data']['access_token'];
        getUserData(token).then(function(response2) {
          var userLogin = {token: token, ...response2['data']['data']}
          context.login(userLogin);
        })
        .catch(function(err) {
          MySwal.fire({
            text: "Utilizador/Password inválidos."
          });
        })
      }
      else {
        console.log("Error: " + response['data']['error']);
        MySwal.fire({
          text: "Utilizador/Password inválidos."
        });
      }
    })
    .catch(function (error) {
      console.log(error.message);
      MySwal.fire({
        text: "Utilizador/Password inválidos."
      });
    });
  }

  function getUserData(token) {
    return axios.post(config.NCAUTH_URL + "/api/token-to-user", {token: token});
  }

  function loginUserCallback() {
    requestLogin();
  }

  return (
    <>
      <div className="row justify-content-center py-5">
        <div className="col-md-4 form-wrapper p-5">
          <form onSubmit={onSubmit} noValidate>
              <h3 className="text-darkblue form-title">Entrar</h3>
              <div className="form-group my-4" style={{textAlign: "left"}}>
                  <label className="form-label text-darkblue">Email ou Utilizador</label>
                  <input className="form-control textbox-blue" type="text" name="username" value={values.username} onChange={onChange} />
              </div>
              <div className="form-group my-4" style={{textAlign: "left"}}>
                  <label className="form-label text-darkblue">NIF da Empresa</label>
                  <input className="form-control textbox-blue" type="text" name="nif_empresa" value={values.nif_empresa} onChange={onChange} />
              </div>
              <div className="form-group my-4" style={{textAlign: "left"}}>
                  <label className="form-label text-darkblue">Password</label>
                  <input className="form-control textbox-blue" type="password" name="password" value={values.password} onChange={onChange} />
              </div>
              <div className="my-5" style={{textAlign: "right"}}>
                <button type="submit" className="btn btn-primary btn-lg btn-blue">Submeter</button>
              </div>
          </form>
          <Link to="/registar">Registar</Link>
        </div>
      </div>
    </>
  )
}
