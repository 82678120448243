import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../context/auth';
import { useForm } from '../util/hooks';
import { Navigate } from 'react-router-dom';
import Footer from './Footer.js';
import config from '../config.json';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal);
const axios = require('axios');

export default function Cliente() {
  const context = useContext(AuthContext);
  const user = context.user;

  const { onChange: onChangePassword, onSubmit: onSubmitPassword, values: passwordValues, setValues: setPasswordValues } = useForm(updatePassword, {
    id: user ? user['id_user'] : "",
    oldPassword: "",
    newPassword: "",
    newPasswordConfirm: "",
  });

  const { onChange: onChangeProfile, onSubmit: onSubmitProfile, values: profileValues, setValues: setProfileValues } = useForm(updateProfile, {
    id: user ? user['id_user'] : "",
    username: user ? user['usercode'] : "",
    fname: user ? user['nome'].substring(0, user['nome'].indexOf(' ')) : "",
    lname: user ? user['nome'].substring(user['nome'].indexOf(' ') + 1) : "",
    email: user ? user['email'] : "",
  });

  const [smsData, setSmsData] = useState("");

  function loadSMSdata() {
    var data = "?" + new URLSearchParams({
      token: localStorage.getItem("jwtToken")
    }).toString();

    axios.get(config.NCSMS_URL + "/SMSdata" + data)
    .then(function(response) {
      console.log(response['data']);
      if (response['data']['status'] == "OK") {
        setSmsData(response['data']['data']['nr_sms']);
      }
      else {
        console.log(response['data']);
        MySwal.fire({
          text: "Ocorreu um erro a obter os dados."
        });
      }
    });
  }

  function requestUpdateProfile() {
    var data = {
      token: localStorage.getItem("jwtToken"),
      id: profileValues.id,
      firstName: profileValues.fname,
      lastName: profileValues.lname,
      username: profileValues.username,
      email: profileValues.email
    }

    axios.post(config.NCAUTH_URL + "/api/update-user", data)
    .then(function (response) {
      if (response['data']['status'] == "OK") {
        context.reloadUser();
        MySwal.fire({
          text: "O utilizador foi atualizado com sucesso."
        });
      }
      else {
        MySwal.fire({
          text: "Ocorreu um erro na atualização."
        });
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  function requestUpdatePassword() {
    var data = {
      token: localStorage.getItem("jwtToken"),
      id: passwordValues.id,
      oldPassword: passwordValues.oldPassword,
      newPassword: passwordValues.newPassword,
      newPasswordConfirm: passwordValues.newPasswordConfirm,
    };
    axios.post(config.NCAUTH_URL + '/api/change-password', data)
    .then(function (response) {
      if (response['data']['status'] == "OK") {
        setPasswordValues({
          id: user ? user['id_user'] : "",
          oldPassword: "",
          newPassword: "",
          newPasswordConfirm: "",
        })
        MySwal.fire({
          text: "A password foi atualizada com sucesso."
        });
      }
      else {
        console.log(response['data']);
        MySwal.fire({
          text: "Password inválida."
        });
      }
    })
    .catch(function (error) {
      console.log(error);
      MySwal.fire({
        text: "Password inválida."
      });
    });
  }

  function updateProfile() {
    requestUpdateProfile();
  }

  function updatePassword() {
    requestUpdatePassword();
  }

  useEffect(() => {
    if (user) {
      loadSMSdata();
    }
  }, []);

  return user ? (
    <>
      <h3>Bemvindo, {user['nome']}.</h3>

      <div className="row justify-content-center py-5">
        <div className="col-md-4 form-wrapper p-5">
          <h3 className="text-darkblue form-title">Saldo SMS</h3>
          <p><b>Créditos disponíveis:</b> {smsData}</p>
        </div>
      </div>

      <div className="row justify-content-center py-5">
        <div className="col-md-4 form-wrapper p-5">
          <form onSubmit={onSubmitProfile} noValidate>
            <h3 className="text-darkblue form-title">Perfil</h3>
            <div className="form-group my-4" style={{ textAlign: "left" }}>
              <label className="form-label text-darkblue">Nome de Utilizador</label>
              <input className="form-control textbox-blue" type="text" name="username" value={profileValues.username} onChange={onChangeProfile} />
            </div>
            <div className="form-group my-4" style={{ textAlign: "left" }}>
              <label className="form-label text-darkblue">Primeiro Nome</label>
              <input className="form-control textbox-blue" type="text" name="fname" value={profileValues.fname} onChange={onChangeProfile} />
            </div>
            <div className="form-group my-4" style={{ textAlign: "left" }}>
              <label className="form-label text-darkblue">Último Nome</label>
              <input className="form-control textbox-blue" type="text" name="lname" value={profileValues.lname} onChange={onChangeProfile} />
            </div>
            <div className="form-group my-4" style={{ textAlign: "left" }}>
              <label className="form-label text-darkblue">Email</label>
              <input className="form-control textbox-blue" type="text" name="email" value={profileValues.email} onChange={onChangeProfile} />
            </div>
            <div className="my-5" style={{ textAlign: "right" }}>
              <input type="hidden" value={profileValues.id} name="id"/>
              <button type="submit" className="btn btn-primary btn-lg btn-blue">Gravar</button>
            </div>
          </form>
        </div>
      </div>
      <div className="row justify-content-center py-5">
        <div className="col-md-4 form-wrapper p-5">
          <h3 className="text-darkblue form-title">Alterar Password</h3>
          <form onSubmit={onSubmitPassword} noValidate>
            <div className="form-group my-4" style={{ textAlign: "left" }}>
              <label className="form-label text-darkblue">Password</label>
              <input className="form-control textbox-blue" type="password" name="oldPassword" value={passwordValues.oldPassword} onChange={onChangePassword} />
            </div>
            <div className="form-group my-4" style={{ textAlign: "left" }}>
              <label className="form-label text-darkblue">Nova Password</label>
              <input className="form-control textbox-blue" type="password" name="newPassword" value={passwordValues.newPassword} onChange={onChangePassword} />
            </div>
            <div className="form-group my-4" style={{ textAlign: "left" }}>
              <label className="form-label text-darkblue">Confirmar Nova Password</label>
              <input className="form-control textbox-blue" type="password" name="newPasswordConfirm" value={passwordValues.newPasswordConfirm} onChange={onChangePassword} />
            </div>
            <div className="my-5" style={{ textAlign: "right" }}>
              <input type="hidden" value={passwordValues.id} name="id"/>
              <button type="submit" className="btn btn-primary btn-lg btn-blue">Gravar</button>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  ) :
  (<Navigate to="/entrar" />)
}
