import React from 'react'
import Precos from './Precos.js';
import Footer from './Footer.js';

export default function PrecosPage() {
  return (
    <>
      <Precos />
      <Footer />
    </>
  )
}
