import React, { useContext, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useForm } from '../util/hooks';
import { AuthContext } from '../context/auth';
import config from '../config.json';
import '../App.css';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal);
const axios = require('axios');

export default function RegisterCompanyForm() {
  const context = useContext(AuthContext);
  const navigate = useNavigate();

  const { onChange, onSubmit, values } = useForm(registerCompany, {
      name: "",
      email: "",
      nif: ""
  });

  function getCompanyId(nif) {
    return axios.post(config.NCAUTH_URL + "/api/get-company", {nif: nif});
  }

  function activateModule(id_company) {
    return axios.post(config.NCAUTH_URL + "/api/activate-module", {id_company: id_company, module_name: "NCSMS"});
  }

  function createSMSaccount(id_company) {
    return axios.post(config.BASE_URL + "/api/create-sms-account", {id_company: id_company});
  }

  function createCompany() {
    axios.post(config.NCAUTH_URL + "/api/nc/create-company", values)
    .then(function (response) {
      if (response['data']['status'] == "OK") {
        getCompanyId(values.nif).then(function(response) {
          var id_company = response['data']['data']['id_company'];
          activateModule(id_company).then(function(response) {
            if (response['data']['status'] == "OK") {
              createSMSaccount(id_company).then(function(response) {
                if (response['data']['status'] == "OK") {
                  MySwal.fire({
                    text: "Empresa registada com sucesso."
                  });
                  navigate("/");
                }
                else {
                  MySwal.fire({
                    text: "Ocorreu um erro a registar a empresa."
                  });
                }
              });
            }
            else {
              MySwal.fire({
                text: "Ocorreu um erro a registar a empresa."
              });
            }
          })
        });
      }
      else {
        MySwal.fire({
          text: "Ocorreu um erro a registar a empresa."
        });
      }
    })
    .catch(function(error) {
      console.log(error.message);
      MySwal.fire({
        text: "Ocorreu um erro a registar a empresa."
      });
    });
  }

  function requestRegister() {
    axios.post(config.NCAUTH_URL + "/api/company-exists", {nif: values.nif})
    .then(function (response) {
      if (response['data']['status'] == "OK") {
        MySwal.fire({
          text: "Esta empresa já está registada."
        });
      }
      else {
        createCompany();
      }
    })
    .catch(function(error) {
      console.log(error);
      MySwal.fire({
        text: "Ocorreu um erro a registar a empresa."
      });
    });
  }

  function registerCompany() {
    requestRegister();
  }
  
  return (
      <div className="row justify-content-center py-5">
        <div className="col-md-4 form-wrapper p-5">
          <form onSubmit={onSubmit} noValidate>
              <h3 className="text-darkblue form-title">Registar Empresa</h3>
              <div className="form-group my-4" style={{textAlign: "left"}}>
                  <label className="form-label text-darkblue">Nome da Empresa</label>
                  <input className="form-control textbox-blue" type="text" name="name" value={values.name} onChange={onChange} />
              </div>
              <div className="form-group my-4" style={{textAlign: "left"}}>
                  <label className="form-label text-darkblue">Email</label>
                  <input className="form-control textbox-blue" type="text" name="email" value={values.email} onChange={onChange} />
              </div>
              <div className="form-group my-4" style={{textAlign: "left"}}>
                  <label className="form-label text-darkblue">NIF</label>
                  <input className="form-control textbox-blue" type="text" name="nif" value={values.nif} onChange={onChange} />
              </div>
              <div className="my-5" style={{textAlign: "right"}}>
                <button type="submit" className="btn btn-primary btn-lg btn-blue">Submeter</button>
              </div>
          </form>
        </div>
      </div>
  )
}
