import React from 'react'

export default function ItemCompra(props) {
  return (
    <tr>
        <td>{props.title}</td> 
        <td>x{props.qtty}</td>
        <td>{props.price}</td>
    </tr>
  )
}
