import React, {useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import './Precos.css';
import { CarrinhoDeComprasContext } from '../context/CarrinhoDeComprasContext.js';

export default function Precos() {
  const [carrinhoDeCompras, setCarrinhoDeCompras] = useContext(CarrinhoDeComprasContext);
  const navigate = useNavigate();
  const items = {
    "sms1": {
      "title": "1000 SMS",
      "subtitle": "1000 Mensagens SMS",
      "nr": 1000,
      "price": 40.00,
      "qtty": 1
    },
    "sms2": {
      "title": "2000 SMS",
      "subtitle": "2000 Mensagens SMS",
      "nr": 2000,
      "price": 70.00,
      "qtty": 1
    },
    "sms3": {
      "title": "3000 SMS",
      "subtitle": "3000 Mensagens SMS",
      "nr": 3000,
      "price": 93.75,
      "qtty": 1
    }
  };

  const addItemToCart = (e) => {
    let title = items[e.target.value]['title'];
    let price = items[e.target.value]['price'];
    let qtty = items[e.target.value]['qtty'];
    let nr = items[e.target.value]['nr'];

    setCarrinhoDeCompras(prevCarrinhoDeCompras => [...prevCarrinhoDeCompras, {
          index: prevCarrinhoDeCompras.length,
          title: title,
          price: price,
          qtty: qtty,
          nr: nr
    }]);

    navigate('/carrinho');
  }

  return (
    <>
      <div className="container mb-5">
        <div className="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
          <h1 className="display-4">Preços</h1>
          <p className="lead">Preçário Pacotes SMS</p>
        </div>
        <div className="row mb-3">
            <div className="col-md-4 gx-5">
              <div className="card mb-4 box-shadow bg-lightgrey">
                <div className="card-header bg-lightgrey2">
                  <h4 className="my-0 font-weight-normal">{items['sms1']['nr']}</h4>
                </div>
                <div className="card-body">
                  <h1 className="card-title pricing-card-title">{items['sms1']['price'] + "€"}</h1>
                  <ul className="list-unstyled mt-3 mb-4">
                    <li>{items['sms1']['subtitle']}</li>
                  </ul>
                  <button type="button" value="sms1" className="btn btn-lg btn-block btn-primary btn-blue" onClick={addItemToCart}>Comprar</button>
                </div>
              </div>
            </div>
            <div className="col-md-4 gx-5">
              <div className="card mb-4 box-shadow bg-lightgrey">
                <div className="card-header bg-lightgrey2">
                  <h4 className="my-0 font-weight-normal">{items['sms2']['nr']}</h4>
                </div>
                <div className="card-body">
                  <h1 className="card-title pricing-card-title">{items['sms2']['price'] + "€"}</h1>
                  <ul className="list-unstyled mt-3 mb-4">
                    <li>{items['sms2']['subtitle']}</li>
                  </ul>
                  <button type="button" value="sms2" className="btn btn-lg btn-block btn-primary btn-blue" onClick={addItemToCart}>Comprar</button>
                </div>
              </div>
            </div>
            <div className="col-md-4 gx-5">
              <div className="card mb-4 box-shadow bg-lightgrey">
                <div className="card-header bg-lightgrey2">
                  <h4 className="my-0 font-weight-normal">{items['sms3']['nr']}</h4>
                </div>
                <div className="card-body">
                  <h1 className="card-title pricing-card-title">{items['sms3']['price'] + "€"}</h1>
                  <ul className="list-unstyled mt-3 mb-4">
                    <li>{items['sms3']['subtitle']}</li>
                  </ul>
                  <button type="button" value="sms3" className="btn btn-lg btn-block btn-primary btn-blue" onClick={addItemToCart}>Comprar</button>
                </div>
              </div>
            </div>
          </div>
      </div>
    </>
  )
}
